/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type HIRL = {
    kind: 'app/server',
    uuid: string;
}
| {
    kind: 'app/database',
    uuid: string;
}
| {
    kind: 'app/postgres',
    uuid: string;
}
| {
    kind: 'app/redis',
    uuid: string;
}
| {
    kind: 'aws/s3-bucket',
    uuid: string;
} | {
    kind: 'elastic/index',
    uuid: string;
}
;

export function stringifyHIRL(hirl: HIRL): string {
    return hirl.kind + '.' + hirl.uuid;
}

export function parseHIRL(hirlString: string): HIRL {
    const [kind, uuid] = hirlString.split('.');
    return {kind: kind as any, uuid};
}