/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { DigitalOceanDatabaseSizeSlug } from '../../types/omni/infrastructure';
import { DatabaseHostingLocationEnum, DatabaseKindEnum } from '../../types/infrastructure/database';

export type ParamsType = {
  name: string;
  hostLocation: DatabaseHostingLocationEnum.digitalOcean;
  variant: DatabaseKindEnum;
  size: DigitalOceanDatabaseSizeSlug;
};
export type ResponseType = { operationUUID: string; databaseUUID: string; };
export const Endpoint = 'infrastructure/provision-database';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"name":{"type":"string"},"hostLocation":{"type":"string","enum":["digitalOcean"]},"variant":{"$ref":"#/definitions/DatabaseKindEnum"},"size":{"$ref":"#/definitions/DigitalOceanDatabaseSizeSlug"}},"required":["hostLocation","name","size","variant"],"definitions":{"DatabaseKindEnum":{"enum":["elasticsearch","mongo","pg","redis"],"type":"string"},"DigitalOceanDatabaseSizeSlug":{"enum":["db-s-16vcpu-64gb","db-s-1vcpu-1gb","db-s-1vcpu-2gb","db-s-2vcpu-4gb","db-s-4vcpu-8gb","db-s-6vcpu-16gb","db-s-8vcpu-32gb"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}